import React from "react"
import { connectStateResults } from "react-instantsearch-dom"

const Title = ({ searchState, data }) => {
  const statusPrefix = {
    en: "to",
    fr: "à",
  }

  const locationPrefix = {
    en: "in",
    fr: "à",
  }

  let propertyStatus =
    searchState.menu && searchState.menu["propertyStatus.name"]
  if (propertyStatus === "Acheter") {
    propertyStatus = "vendre"
  } else if (propertyStatus === "Location") {
    propertyStatus = "louer"
  }

  const status =
    propertyStatus &&
    `${statusPrefix[data.locale]} ${propertyStatus.toLowerCase()}`

  const city = searchState.menu && searchState.menu["city.name"]
  const country = searchState.menu && searchState.menu["city.country.name"]

  let location = ""
  if (city && country) {
    location = `${locationPrefix[data.locale]} ${city}, ${country}`
  } else if (city || country) {
    location = `${locationPrefix[data.locale]} ${city || country}`
  }

  return (
    <h1 className="text-2xl font-bold">
      {JSON.stringify()}
      {data.heading} {status} {location}
    </h1>
  )
}

export default connectStateResults(Title)
