import React from "react"
import PropTypes from "prop-types"

const Heading = ({ title, children }) => (
  <div className="mb-6">
    <label className="block mb-2 text-xs font-bold leading-none tracking-wide text-gray-400 uppercase">
      {title}
    </label>
    {children}
  </div>
)

Heading.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Heading
