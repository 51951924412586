import React, { useContext } from "react"

import Heading from "./Heading"
import {
  CustomSortBy,
  CustomMenuSelect,
  CustomRangeInput,
  CustomRefinementList,
  CustomMinRangeInput,
  CustomClearRefinements,
} from "../../components/search"

import { Locale } from "../../context"

const Filters = ({ data }) => {
  let { locale } = useContext(Locale)

  const index = `${process.env.GATSBY_ALGOLIA_INDEX_NAME}_${locale}`

  return (
    <section>
      <Heading title={data.propertyStatus}>
        <CustomMenuSelect
          attribute="propertyStatus.name"
          placeholder={data.propertyStatusPlaceholder}
        />
      </Heading>
      <Heading title={data.country}>
        <CustomMenuSelect
          attribute="city.country.name"
          placeholder={data.countryPlaceholder}
          limit={999}
          transformItems={items =>
            items.sort((a, b) => a.label.localeCompare(b.label))
          }
        />
      </Heading>
      <Heading title={data.city}>
        <CustomMenuSelect
          attribute="city.name"
          placeholder={data.cityPlaceholder}
          limit={999}
          transformItems={items =>
            items.sort((a, b) => a.label.localeCompare(b.label))
          }
        />
      </Heading>
      <Heading title={data.price}>
        <CustomRangeInput min={0} attribute="price" />
      </Heading>
      <div className="flex items-center -mx-2">
        <div className="w-1/2 px-2">
          <Heading title={data.bedrooms}>
            <CustomMinRangeInput attribute="bedrooms" />
          </Heading>
        </div>
        <div className="w-1/2 px-2">
          <Heading title={data.bathrooms}>
            <CustomMinRangeInput attribute="bathrooms" />
          </Heading>
        </div>
      </div>
      <Heading title={data.sortBy}>
        <CustomSortBy
          defaultRefinement={index}
          items={[
            { value: index, label: data.sortByFeatured },
            { value: `${index}_price_asc`, label: data.sortByPriceAsc },
            { value: `${index}_price_desc`, label: data.sortByPriceDesc },
          ]}
        />
      </Heading>
      <Heading title={data.propertyType}>
        <CustomRefinementList attribute="propertyType.name" limit={20} />
      </Heading>
      <Heading title={data.amenities}>
        <CustomRefinementList
          attribute="amenities"
          operator="and"
          limit={5}
          showMore
          showMoreLimit={100}
          showLessTitle={data.showLess}
          showMoreTitle={data.showMore}
        />
      </Heading>
      <CustomClearRefinements title={data.reset} />
    </section>
  )
}

export default Filters
